// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDv6_PNKero10bTsYDhbV0fXRVTBWLjLvQ",
    authDomain: "maqturaapp.firebaseapp.com",
    databaseURL: "https://maqturaapp-default-rtdb.firebaseio.com",
    projectId: "maqturaapp",
    storageBucket: "maqturaapp.appspot.com",
    messagingSenderId: "118701349987",
    appId: "1:118701349987:web:189eab3f0c10e08c152c56"

  },
  // base_url:"http://127.0.0.1:3000/api",
  base_url:"https://maqtura-922a2f339241.herokuapp.com/api",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
